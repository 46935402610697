import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    nickName: '',
    avatorUrl: '',
    token: '',
    groupName: '',
    groupAvatarUrl: '',
    roleType:'',
  },
  reducers: {
    setUserInfo: (state, action) => {
      const {nickName, avatorUrl, token, groupName, groupAvatarUrl, roleType} = action.payload;
      state.nickName = nickName;
      state.avatorUrl = avatorUrl;
      state.token = token;
      state.groupName = groupName;
      state.groupAvatarUrl = groupAvatarUrl;
      state.roleType = roleType;
    },


    clearUserInfo: (state) => {
      state.nickName = '';
      state.avatorUrl = '';
      state.token = '';
      state.groupName = '';
      state.groupAvatarUrl = '';
      state.roleType = '';
    },
  }
});

export const { setUserInfo,clearUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;