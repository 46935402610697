import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store,persistor} from "./app/store/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import SuspenseContent from "./containers/SuspenseContent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<SuspenseContent />}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Suspense>
);
