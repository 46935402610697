import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import headerSlice from "../../features/common/headerSlice";
import modalSlice from "../../features/common/modalSlice";
import rightDrawerSlice from "../../features/common/rightDrawerSlice";
import leadsSlice from "../../features/leads/leadSlice";
import userInfoSlice from "./userinfoSlice";

import { persistStore, persistReducer } from "redux-persist";

import storageSession from "redux-persist/lib/storage/session";

// 数据对象
const storageConfig = {
  key: "root", // 必须有的
  storage: storageSession,
  whitelist: ["userInfo"], // 缓存机制
};

const reducers = combineReducers({
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  userInfo: userInfoSlice,
});

const persistedReducer = persistReducer(storageConfig, reducers);



const store = configureStore({
  reducer: persistedReducer
});

const persistor = persistStore(store);
export { store, persistor };
